<template>
    <div>
        <header>
            <HeaderNavbar />
        </header>
        <main class="main">
            <div class="container">
                <a-page-header
                    title="Import"
                    sub-title="Reciepts data from your computer"
                    @back="gotoHome"
                />
                <div class="import-content">
                    <div v-if="fileData.length === 0">
                        <a-upload-dragger
                            name="file"
                            :file-list="fileList"
                            :remove="handleRemove"
                            :beforeUpload="beforeUpload"
                        >
                            <p class="ant-upload-drag-icon"><a-icon type="inbox" /></p>
                            <p class="ant-upload-text">Click or drag file to this area to upload</p>
                            <p class="ant-upload-hint">
                                Support CSV (Comma-separated values) file.
                            </p>
                        </a-upload-dragger>
                        <div class="container-flex" style="margin-top: 2rem">
                            <div>
                                Need a Sample file?
                                <a href="./sample.csv">Click here</a> to download
                            </div>
                            <div>
                                <a-button type="secondary" style="margin: 0 8px" @click="gotoHome"
                                    >Cancel</a-button
                                >
                                <a-button
                                    type="primary"
                                    :disabled="fileList.length === 0"
                                    @click="handleParsing"
                                    >Continute</a-button
                                >
                            </div>
                        </div>
                    </div>
                    <div v-else>
                        <a-row class="import-preview">
                            <a-col :span="24" :lg="{ span: 12 }">
                                <p>
                                    Code: <strong>{{ preview.code }}</strong>
                                </p>
                                <p>
                                    Họ và tên: <strong>{{ preview.fullname }}</strong>
                                </p>
                                <p>
                                    Mã số thuế: <strong>{{ preview.taxcode }}</strong>
                                </p>
                                <p>
                                    Quốc tịch:
                                    <strong>{{ preview.nationality }}</strong>
                                </p>
                                <p>
                                    Ngày đến Việt Nam:
                                    <strong>{{ preview.arrival_date }}</strong>
                                </p>
                                <p>
                                    Cá nhân cư trú:
                                    <strong>{{ preview.resident }}</strong>
                                </p>
                                <p>
                                    Địa chỉ hoặc điện thoại liên hệ:
                                    <strong>{{ preview.contact_address_phone }}</strong>
                                </p>
                                <p>
                                    Số CMND/Hộ chiếu:
                                    <strong>{{ preview.id_number }}</strong>
                                </p>
                                <p>
                                    Ngày cấp: <strong>{{ preview.id_issued }}</strong>
                                </p>
                                <p>
                                    Nơi cấp: <strong>{{ preview.id_place }}</strong>
                                </p>
                            </a-col>
                            <a-col :span="24" :lg="{ span: 12 }">
                                <p>
                                    Khoản thu nhập:
                                    <strong>{{ preview.income_type }}</strong>
                                </p>
                                <p>
                                    Thu nhập từ ngày:
                                    <strong>{{ preview.income_from }}</strong>
                                </p>
                                <p>
                                    Đến ngày: <strong>{{ preview.income_to }}</strong>
                                </p>
                                <p>
                                    Tổng thu nhập chịu thuế:
                                    <strong>{{ `${formatNumber(preview.income_gross)}` }}</strong>
                                </p>
                                <p>
                                    Số thuế TNCN đã khấu trừ:
                                    <strong>{{ `${formatNumber(preview.income_tax)}` }}</strong>
                                </p>
                                <p>
                                    Tổng thu nhập tính thuế:
                                    <strong>{{
                                        `${formatNumber(preview.total_tax_calculation_income)}`
                                    }}</strong>
                                </p>
                                <p>
                                    Bảo hiểm được trừ:
                                    <strong>{{ `${formatNumber(preview.insurances)}` }}</strong>
                                </p>
                                <p>
                                    Thời điểm trả thu nhập:
                                    <strong>{{ preview.payment_time }}</strong>
                                </p>
                                <p>
                                    Tiền thuê nhà tại Việt Nam:
                                    <strong>{{ preview.house_rental }}</strong>
                                </p>
                                <p>
                                    Người đại diện (NĐD):
                                    <strong>{{ preview.payment_sign }}</strong>
                                </p>
                                <p>
                                    Chức danh NĐD:
                                    <strong>{{ preview.payment_title }}</strong>
                                </p>
                            </a-col>
                        </a-row>
                        <div class="container-flex" style="margin-top: 2rem">
                            <div>
                                Preview <strong>{{ viewing + 1 }}</strong> of
                                <strong>{{ fileData.length }}</strong>
                                <a-button
                                    @click="previewPrev"
                                    :disabled="viewing === 0"
                                    style="margin: 0 8px; padding: 0 8px"
                                    ><a-icon type="caret-left"
                                /></a-button>
                                <a-button
                                    @click="previewNext"
                                    :disabled="viewing === fileData.length - 1"
                                    style="padding: 0 8px"
                                    ><a-icon type="caret-right"
                                /></a-button>
                            </div>
                            <div style="text-align: right">
                                <a-button @click="handleCancel" style="margin: 0 8px"
                                    >Go Back</a-button
                                >
                                <a-button type="primary" @click="handleRequest"
                                    >Submit All</a-button
                                >
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    </div>
</template>
<script>
import Papa from 'papaparse';
import HeaderNavbar from '@/components/HeaderNavbar.vue';

function isValidDate(dateString) {
    let regEx = /^\d{4}-\d{2}-\d{2}$/;

    if (!dateString.match(regEx)) return false; // Invalid format

    let d = new Date(dateString);
    let dNum = d.getTime();

    if (!dNum && dNum !== 0) return false; // NaN value, Invalid date

    return d.toISOString().slice(0, 10) === dateString;
}

export default {
    data: function () {
        return {
            fileList: [],
            fileData: [],
            viewing: 0,
        };
    },
    mounted() {
        document.title = 'PIT System - Import';
    },
    computed: {
        preview: function () {
            return this.fileData[this.viewing];
        },
    },
    methods: {
        formatNumber: function (aNumber) {
            return new Intl.NumberFormat('vi').format(aNumber);
        },
        gotoHome: function () {
            this.$router.push('/');
        },
        beforeUpload: function (file) {
            if (file.name.split('.').pop() === 'csv') {
                this.fileList = [file];
            } else {
                this.$message.error('Please select a CSV file');
            }

            return false;
        },
        handleRemove: function () {
            this.fileList = [];
        },
        handleParsing: function () {
            Papa.parse(this.fileList[0], {
                header: true,
                encoding: 'UTF-8',
                skipEmptyLines: true,
                complete: (results) => {
                    if (results.errors.length > 0) {
                        return this.$message.error(results.errors[0].message);
                    }

                    let items = results.data;

                    if (items.length === 0) {
                        return this.$message.error('File data is empty!');
                    }

                    const requireFields = [
                        'code',
                        'fullname',
                        'taxcode',
                        'nationality',
                        'resident',
                        'id_number',
                        'id_issued',
                        'id_place',
                        'arrival_date',
                        'income_type',
                        'income_from',
                        'income_to',
                        'income_gross',
                        'income_tax',
                        'insurances',
                        'payment_time',
                        'payment_sign',
                        'payment_title',
                        'house_rental',
                        'contact_address_phone',
                        'total_tax_calculation_income',
                    ];

                    for (var i = requireFields.length - 1; i >= 0; i--) {
                        if (!results.meta.fields.includes(requireFields[i])) {
                            return this.$message.error(
                                "Column '" + requireFields[i] + "' is not found!",
                            );
                        }
                    }

                    for (var j = items.length - 1; j >= 0; j--) {
                        if (!isValidDate(items[j].id_issued)) {
                            return this.$message.error('Value(s) of "id_issued" is not valid!');
                        }

                        // if ( !isValidDate( items[j].arrival_date ) ) return this.$message.error('Value(s) of "arrival_date" is not valid!');
                        if (!isValidDate(items[j].income_from))
                            return this.$message.error('Value(s) of "income_from" is not valid!');
                        if (!isValidDate(items[j].income_to))
                            return this.$message.error('Value(s) of "income_to" is not valid!');
                        if (!isValidDate(items[j].payment_time))
                            return this.$message.error('Value(s) of "payment_time" is not valid!');
                        if (isNaN(items[j].income_gross))
                            return this.$message.error(
                                'Value(s) of "income_gross" is not a number!',
                            );
                        if (isNaN(items[j].income_tax))
                            return this.$message.error('Value(s) of "income_tax" is not a number!');
                        if (isNaN(items[j].insurances))
                            return this.$message.error('Value(s) of "insurances" is not a number!');
                        if (isNaN(items[j].house_rental))
                            return this.$message.error(
                                'Value(s) of "house_rental" is not a number!',
                            );
                        if (isNaN(items[j].total_tax_calculation_income))
                            return this.$message.error(
                                'Value(s) of "total_tax_calculation_income" is not a number!',
                            );
                    }

                    this.handlePreview(items);
                },
            });
        },
        handlePreview: function (items) {
            this.fileData = items;
        },
        previewPrev: function () {
            if (this.viewing > 0) this.viewing--;
        },
        previewNext: function () {
            if (this.viewing < this.fileData.length - 1) this.viewing++;
        },
        handleCancel: function () {
            this.fileList = [];
            this.fileData = [];
            this.viewing = 0;
        },
        handleRequest: function () {
            this.$http.post('/posts/import', this.fileData).then((response) => {
                if (response.data.success) {
                    this.$message.success(response.data.message);
                    this.$router.push('/');
                } else {
                    this.$message.error(response.data.message);
                }
            });
        },
    },
    components: {
        HeaderNavbar,
    },
};
</script>
<style lang="scss">
.import-content {
    max-width: 720px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2rem;
}

.import-preview {
    background-color: #fafafa;
    padding: 20px 20px 5px;
    border: 1px solid #d9d9d9;
    border-radius: 5px;
}

.ant-upload.ant-upload-drag .ant-upload-btn {
    min-height: 270px;
}
</style>
